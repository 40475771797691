<script setup lang="ts">
import { useHead } from '#app';
import { getRewardfulScripts } from '#imports';
import LoginForm from '~/pages/login/components/login-form.vue';

useHead({
  title: 'Login | WeEngrave',
  script: [
    ...getRewardfulScripts(),
  ],
});
</script>

<template>
  <div class="container mx-auto min-h-dvh flex flex-col justify-center items-center py-10">
    <h1 class="text-h3 text-center w-full">
      Welcome Back!
    </h1>

    <LoginForm class="mt-38" />
  </div>
</template>
